import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { graphql, Link } from 'gatsby';
import { orderBy } from 'lodash';
import { renderAbstract } from '@utils';
import '@scss/research-page.scss';

const ResearchListPage = ({ data }) => {
  const { allResearchProjects } = data.postgres;

  const renderResearchBlocks = () => orderBy(allResearchProjects, `paperId`)
    ?.map(item =>
      item.topic === `Research` &&
        <Col md={6} lg={3} key={item.id}>
          <div className="presentation">
            <div className="name">
              <h2>{item.title}</h2>
            </div>
            <div className="presentation-overlay">
              <Link to={`/${item.year}/research/${item.id}`}>
                <p>
                  { renderAbstract(item.abstract) }
                </p>
              </Link>
            </div>
          </div>
        </Col>);
  const render10MinTalks = () => orderBy(allResearchProjects, `paperId`)
    ?.map(item =>
      item.topic === `10-Minute Talks` &&
        <Col md={6} lg={3} key={item.id}>
          <div className="presentation">
            <div className="name">
              <h2>{item.title}</h2>
            </div>
            <div className="presentation-overlay">
              <Link to={`/${item.year}/research/${item.id}`}>
                <p>
                  { renderAbstract(item.abstract) }
                </p>
              </Link>
            </div>
          </div>
        </Col>);
  const renderLightningDemos = () => orderBy(allResearchProjects, `paperId`)
    ?.map(item =>
      item.topic === `Lightning Demos` &&
        <Col md={6} lg={3} key={item.id}>
          <div className="presentation">
            <div className="name">
              <h2>{item.title}</h2>
            </div>
            <div className="presentation-overlay">
              <Link to={`/research/${item.id}`}>
                <p>
                  { renderAbstract(item.abstract) }
                </p>
              </Link>
            </div>
          </div>
        </Col>);

  return <Layout className="research-page">
    <Navbar year={allResearchProjects[0].year} />
    <PageHeader
      title="Research Symposium Presentations"
      titleTransform="uppercase"
    />
    <Container className="presentations-div">
      {!!allResearchProjects?.filter(item => item.topic === `Research`).length && <div class="outer-div">
        <h2 style={{ padding: `10px`, textAlign: `center` }}>Presentations</h2>
        <hr />
        <Row>
          {renderResearchBlocks()}
        </Row>
      </div>}
      {!!allResearchProjects?.filter(item => item.topic === `10-Minute Talks`).length && <div class="outer-div">
        <h2 style={{ padding: `10px`, textAlign: `center` }}>Presentations</h2>
        <hr />
        <Row>
          {render10MinTalks()}
        </Row>
      </div>}
      {!!allResearchProjects?.filter(item => item.topic === `Lightning Demos`).length && <div class="outer-div">
        <h2 style={{ padding: `10px`, textAlign: `center` }}>Lightning Demos</h2>
        <hr />
        <Row>
          {renderLightningDemos()}
        </Row>
      </div>}
    </Container>
    <Sponsor year={allResearchProjects[0].year} />
    <Footer />

  </Layout>;
};

export const query = graphql`
query ResearchList($year: String) {
  postgres {
    allResearchProjects: allResearchProjectsList(condition: { year: $year }){
      id
      title
      topic
      abstract
      year
      paperId
    }
  }
}
`;

export default ResearchListPage;
